import {
  ROOT,
  GET_LIST_TEE_BOOKING_API,
  GET_LIST_NATIONAL_API,
  GET_LIST_BOOKING_API,
  CREATE_BOOKING_API,
  GOLF_CLASSES_API,
  RESOURCES_API,
  DETAIL_BOOKING_2A_API,
  DETAIL_BOOKING_2B_API,
  DETAIL_BOOKING_2C_API,
  RATE_CODE_API,
  DETAIL_TEE_TIME_API,
  CHECK_IN,
  GET_LIST_GROUP_API,
  COMBINE_GROUP_API,
  SOURCES_LIST_API,
  UPDATE_RATE_CODE_API,
  SPLITGROUP_API,
  GET_LIST_CADDY,
  LIST_SERVICES_API,
  LIST_CADDY,
  UPDATE_CADDY,
  BOOKING_UPDATE_CADDY,
  UPDATE_BOOKING_INFO,
  ADD_MEMBER_API,
  CHECK_OUT,
  GET_LIST_PAYMENT,
  GET_CONFIG_API,
  NO_SHOW,
  CREATE_PAYMENT_API,
  CREATE_PAYMENT_DETAIL_API,
  GET_LIST_PAYMENT_METHOD,
  GET_LIST_PAYMENT_DETAIL,
  DELETE_PAYMENT,
  DELETE_PAYMENT_DETAIL,
  GET_LIST_SERVICES, GET_LIST_REASON_DISCOUNT, UPDATE_TEE_TIME_FOR_BOOKING, UPDATE_LOCKER_AND_NOTE_FOR_BOOKING, UPDATE_BOOKING_SERVICE,UPDATE_INFO_EINVOICE_BOOKING_DETAIL,
  API_UBK09,
  GET_BOOKING_RETAIL,
  API_RBK09,
  API_RBK02D,
  API_RCS01,
  API_RME05,
  API_UBK04,
  API_UBK07,
  API_DBK02,
  API_DBK01,
  API_UPM02,
  API_ICI02,
  API_RPM02D,
  API_IPM02D,
  API_DPM02D,
  API_UPM02D,
  API_RPF02,
  API_RHS01,
  RIV01,
  API_RIV01,
  API_UPM01,
  API_UBK12,
  API_UBK13,
  API_RTE03,
  API_RTB02,
  API_RBK10,
  API_DPM06,
  API_RPM02M,
  API_IPM02R,
  API_IEM01,
  API_IEM02,
  API_DPM07,
  API_UBK11,
  API_RME01F,
  API_DPM09,
  API_RRA05,
  API_ICI03,
  API_VC_RV03,
  API_VC_RV04,
  API_VC_RV05,
  API_UBK10,
  API_UPM06,
  API_RRPDF,
  API_RBK06,
  API_VC_RV06,
  API_RRA04A,
  API_UBK15,
  API_RBK12,
  API_RCL03,
  API_UPM07,
  API_RPM01F,
  API_UBKWL1,
  API_RBKWL1,
  API_DBKWL1,
  API_RBK13,
  API_IBKR01,
  API_DBKR01,
  API_RBKR01,
  API_UBK16,
  API_RBK15,
  API_UBK17,
  PUBLISH_VIEW_EINVOICE_COURSE
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'
import { handleResponseFile } from '@/api/response-file'
function api_UBK17(data) {
  return fetch(ROOT + API_UBK17, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RBK13(data) {
  return fetch(ROOT + API_RBK13, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RBK15(data) {
  return fetch(ROOT + API_RBK15, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UBK16(data) {
  return fetch(ROOT + API_UBK16, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_IBKR01(data) {
  return fetch(ROOT + API_IBKR01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DBKR01(data) {
  return fetch(ROOT + API_DBKR01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RBKR01(data) {
  return fetch(ROOT + API_RBKR01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListTeeTimeBooking(data) {
  return fetch(ROOT + GET_LIST_TEE_BOOKING_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UBKWL1(data) {
  return fetch(ROOT + API_UBKWL1, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RBKWL1(data) {
  return fetch(ROOT + API_RBKWL1, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_DBKWL1(data) {
  return fetch(ROOT + API_DBKWL1, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RPM01F(data) {
  return fetch(ROOT + API_RPM01F, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RCL03(data) {
  return fetch(ROOT + API_RCL03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListNational(data) {
  return fetch(ROOT + GET_LIST_NATIONAL_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListGroup(data) {
  return fetch(ROOT + GET_LIST_GROUP_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListBooking(data) {
  return fetch(ROOT + GET_LIST_BOOKING_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getGolfClasses(data) {
  return fetch(ROOT + GOLF_CLASSES_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListResources(data) {
  return fetch(ROOT + RESOURCES_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function createBooking(data) {
  return fetch(ROOT + CREATE_BOOKING_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addMember(data) {
  return fetch(ROOT + ADD_MEMBER_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailBooking2A(data) {
  return fetch(ROOT + DETAIL_BOOKING_2A_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailBooking2B(data) {
  return fetch(ROOT + DETAIL_BOOKING_2B_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailBooking2C(data) {
  return fetch(ROOT + DETAIL_BOOKING_2C_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getRateCode(data) {
  return fetch(ROOT + RATE_CODE_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function checkIn(data) {
  return fetch(ROOT + CHECK_IN, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function combineGroup(data) {
  return fetch(ROOT + COMBINE_GROUP_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListSources(data) {
  return fetch(ROOT + SOURCES_LIST_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateRateCode(data) {
  return fetch(ROOT + UPDATE_RATE_CODE_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function SplitGroup(data) {
  return fetch(ROOT + SPLITGROUP_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateCaddy(data) {
  return fetch(ROOT + BOOKING_UPDATE_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListCaddy(data) {
  return fetch(ROOT + LIST_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListServices(data) {
  return fetch(ROOT + LIST_SERVICES_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateBookingInfo(data) {
  return fetch(ROOT + UPDATE_BOOKING_INFO, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getConfigInfo(data) {
  return fetch(ROOT + GET_CONFIG_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function noShow(data) {
  return fetch(ROOT + NO_SHOW, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getRBK02C(data) {
  return fetch(ROOT + CHECK_OUT, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RBK02D(data) {
  return fetch(ROOT + API_RBK02D, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getRPM01(data) {
  return fetch(ROOT + GET_LIST_PAYMENT, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function createPayment(data) {
  return fetch(ROOT + CREATE_PAYMENT_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function createPaymentDetail(data) {
  return fetch(ROOT + CREATE_PAYMENT_DETAIL_API, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListPaymentMethod(data) {
  return fetch(ROOT + GET_LIST_PAYMENT_METHOD, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListPaymentDetail(data) {
  return fetch(ROOT + GET_LIST_PAYMENT_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateBookingService(data) {
  return fetch(ROOT + UPDATE_BOOKING_SERVICE, requestOptions.postBody(data)).then(handleResponse)
    .then(data => data)
}

function deletePaymentDetail(data) {
  return fetch(ROOT + DELETE_PAYMENT_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deletePayment(data) {
  return fetch(ROOT + DELETE_PAYMENT, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListServicesFee(data) {
  return fetch(ROOT + GET_LIST_SERVICES, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListReasonDiscount(data) {
  return fetch(ROOT + GET_LIST_REASON_DISCOUNT, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateTeeTimeForBooking(data) {
  return fetch(ROOT + UPDATE_TEE_TIME_FOR_BOOKING, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateNoteAndLockerForBooking(data) {
  return fetch(ROOT + UPDATE_LOCKER_AND_NOTE_FOR_BOOKING, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function updateInfoEinvoiceBookingDetail(data) {
  return fetch(ROOT + UPDATE_INFO_EINVOICE_BOOKING_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK09(data) {
  return fetch(ROOT + API_UBK09, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getBookingRetail(data) {
  return fetch(ROOT + GET_BOOKING_RETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RBK09(data) {
  return fetch(ROOT + API_RBK09, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RCS01(data) {
  return fetch(ROOT + API_RCS01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RME05(data) {
  return fetch(ROOT + API_RME05, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK04(data) {
  return fetch(ROOT + API_UBK04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK07(data) {
  return fetch(ROOT + API_UBK07, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DBK01(data) {
  return fetch(ROOT + API_DBK01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_DBK02(data) {
  return fetch(ROOT + API_DBK02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UPM02(data) {
  return fetch(ROOT + API_UPM02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_ICI02(data) {
  return fetch(ROOT + API_ICI02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RPM02D(data) {
  return fetch(ROOT + API_RPM02D, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IPM02D(data) {
  return fetch(ROOT + API_IPM02D, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DPM02D(data) {
  return fetch(ROOT + API_DPM02D, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UPM02D(data) {
  return fetch(ROOT + API_UPM02D, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RPF02(data) {
  return fetch(ROOT + API_RPF02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RHS01(data) {
  return fetch(ROOT + API_RHS01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RIV01(data) {
  return fetch(ROOT + API_RIV01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UPM01(data) {
  return fetch(ROOT + API_UPM01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK12(data) {
  return fetch(ROOT + API_UBK12, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK13(data) {
  return fetch(ROOT + API_UBK13, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RTE03(data) {
  return fetch(ROOT + API_RTE03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RTB02(data) {
  return fetch(ROOT + API_RTB02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RBK10(data) {
  return fetch(ROOT + API_RBK10, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_DPM06(data) {
  return fetch(ROOT + API_DPM06, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RPM02M(data) {
  return fetch(ROOT + API_RPM02M, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IPM02R(data) {
  return fetch(ROOT + API_IPM02R, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IEM01(data) {
  return fetch(ROOT + API_IEM01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IEM02(data) {
  return fetch(ROOT + API_IEM02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DPM07(data) {
  return fetch(ROOT + API_DPM07, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK11(data) {
  return fetch(ROOT + API_UBK11, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RME01F(data) {
  return fetch(ROOT + API_RME01F, requestOptions.postBody(data)).then(handleResponse)
    .then(data => data)
}
function api_DPM09(data) {
  return fetch(ROOT + API_DPM09, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RRA05(data) {
  return fetch(ROOT + API_RRA05, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_ICI03(data) {
  return fetch(ROOT + API_ICI03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_VC_RV03(data) {
  return fetch(ROOT + API_VC_RV03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_VC_RV04(data) {
  return fetch(ROOT + API_VC_RV04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_VC_RV05(data) {
  return fetch(ROOT + API_VC_RV05, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK10(data) {
  return fetch(ROOT + API_UBK10, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UPM06(data) {
  return fetch(ROOT + API_UPM06, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RRPDF(data) {
  return fetch(API_RRPDF, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RBK06(data) {
  return fetch(ROOT + API_RBK06, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_VC_RV06(data) {
  return fetch(ROOT + API_VC_RV06, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RRA04A(data) {
  return fetch(ROOT + API_RRA04A, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK15(data) {
  return fetch(ROOT + API_UBK15, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RBK12(data) {
  return fetch(ROOT + API_RBK12, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UPM07(data) {
  return fetch(ROOT + API_UPM07, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_IPM07_PUBLISH_VIEW_EINVOICE_COURSE(data) {
  return fetch(ROOT + PUBLISH_VIEW_EINVOICE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const booking = {
  getListTeeTimeBooking,
  getListNational,
  getListGroup,
  getListBooking,
  getGolfClasses,
  createBooking,
  addMember,
  getListCaddy,
  getListResources,
  getDetailBooking2A,
  getDetailBooking2B,
  getDetailBooking2C,
  getRateCode,
  SplitGroup,
  noShow,
  updateCaddy,
  checkIn,
  combineGroup,
  getListSources,
  updateRateCode,
  getListServices,
  updateBookingInfo,
  getConfigInfo,
  getRBK02C,
  getRPM01,
  createPayment,
  createPaymentDetail,
  getListPaymentMethod,
  getListPaymentDetail,
  deletePaymentDetail,
  deletePayment,
  getListServicesFee,
  getListReasonDiscount,
  updateTeeTimeForBooking,
  updateNoteAndLockerForBooking,
  updateBookingService,
  api_UBK09,
  getBookingRetail,
  api_RBK09,
  api_RBK02D,
  api_RCS01,
  api_RME05,
  api_UBK04,
  api_UBK07,
  api_DBK01,
  api_DBK02,
  api_UPM02,
  api_ICI02,
  api_RPM02D,
  api_IPM02D,
  api_DPM02D,
  api_UPM02D,
  api_RPF02,
  api_RHS01,
  api_RIV01,
  api_UPM01,
  api_UBK12,
  api_UBK13,
  api_RTE03,
  api_RTB02,
  api_RBK10,
  api_DPM06,
  api_RPM02M,
  api_IPM02R,
  api_IEM01,
  api_IEM02,
  api_DPM07,
  api_UBK11,
  api_RME01F,
  api_DPM09,
  api_RRA05,
  api_ICI03,
  api_VC_RV03,
  api_VC_RV04,
  api_VC_RV05,
  api_UBK10,
  api_UPM06,
  api_RRPDF,
  api_RBK06,
  api_VC_RV06,
  api_RRA04A,
  api_UBK15,
  api_RBK12,
  api_RCL03,
  api_UPM07,
  api_RPM01F,
  api_UBKWL1,
  api_RBKWL1,
  api_DBKWL1,
  api_RBK13,
  api_IBKR01,
  api_DBKR01,
  api_RBKR01,
  api_UBK16,
  api_RBK15,
  api_UBK17,
  updateInfoEinvoiceBookingDetail,
  api_IPM07_PUBLISH_VIEW_EINVOICE_COURSE,
}
